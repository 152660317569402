// This page is redirected to when a user signs up for the newsletter (linked via rapidmail)

import React from "react"
import { Link } from "gatsby"
import { Row, Col, Button } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Confirmed = () => {
  return (
    <Layout style={{ backgroundColor: "#b5b8d7ff" }}>
      <SEO title="Thank you!" />
      <Row
        className="mx-0 w-100"
        style={{ marginTop: "-73px" }}
        className="training-section d-flex flex-row justify-content-center"
      >
        <Col className="px-0" xs={10} md={8} lg={6}>
          <div className="w-100 d-flex flex-column  ">
            <h1 className="sectionTitle">Thank you!</h1>
            <p className="text-center">
              Your email address has been confirmed.
            </p>

            <Link to="/" className="text-center">
              <Button size="lg" className="my-5 buttonStyle">
                Lightschools home page >
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Confirmed
